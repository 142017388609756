<template>
    <div class="k-flex-col w-100">
        <div class="text-center mb-2">
            <h1>NOUVEAU MOT DE PASSE</h1>
        </div>
        <div class="k-form-fp">
            <FormResetPasswordToken />
        </div>
    </div>
</template>

<script>
import FormResetPasswordToken from "@/components/misc/form/FormResetPasswordToken.vue";
export default {
    name: "ResetPassword",
    components: {
        FormResetPasswordToken,
    },
};
</script>
