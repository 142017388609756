<template>
    <div class="k-flex-col">
        <b-form-group>
            <label> <b>Adresse e-mail</b> </label>
            <b-form-input
                v-model="mail"
                :state="isMailValid"
                placeholder="Adresse e-mail"
                autocomplete="nope"
                trim
            />
            <b-form-invalid-feedback :state="isMailValid">
                Veuillez saisir une adresse e-mail valide
            </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group>
            <label><b>Nouveau mot de passe</b></label>
            <b-form-input
                v-model="newPassword"
                type="password"
                placeholder="Mot de passe"
                autocomplete="new-password"
            ></b-form-input>
            <b-form-invalid-feedback :state="isNewPasswordValid">
                Votre mot de passe doit contenir au moins 8 caractères
            </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group>
            <label><b>Confirmation du nouveau mot de passe</b></label>
            <b-form-input
                v-model="newPasswordConfirmation"
                type="password"
                placeholder="Mot de passe"
                autocomplete="nope"
            ></b-form-input>
            <b-form-invalid-feedback :state="isNewPasswordMatching">
                Les mots de passe ne correspondent pas
            </b-form-invalid-feedback>
        </b-form-group>

        <b-button
            class="k-flex-center"
            variant="kalkin-2"
            @click="resetPassword"
            :disabled="!isValid"
        >
            Valider votre nouveau mot de passe
        </b-button>
    </div>
</template>

<script>
import validator from "@/tools/validator";

export default {
    data: function() {
        return {
            mail: null,
            newPassword: "",
            newPasswordConfirmation: "",
        };
    },

    computed: {
        isMailValid() {
            if (this.mail === null) {
                return null;
            } else {
                return validator.validateEmail(this.mail);
            }
        },

        isNewPasswordValid() {
            return this.newPassword.length === 0 || this.newPassword.length > 7;
        },

        isNewPasswordMatching() {
            return this.newPassword === this.newPasswordConfirmation;
        },

        isPasswordValid() {
            return this.newPassword.length > 7 && this.isNewPasswordMatching;
        },

        isValid() {
            return this.isPasswordValid && this.isMailValid;
        },
    },

    methods: {
        async resetPassword() {
            if (this.isValid) {
                await this.$store.dispatch("user/resetPasswordValidate", {
                    mail: this.mail,
                    password: this.newPassword,
                    token: this.$route.params.token,
                });
                this.$router.push({ name: "Login" });
            }
        },
    },
};
</script>
